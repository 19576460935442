import { useState } from "react";
import { NewSubscriptionStyle } from "./NewSubscription.style";

function NewSubscription({ isModalVisible, setIsModalVisible }) {
  const [seeMore, setSeeMore] = useState(false)
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <NewSubscriptionStyle
      visible={isModalVisible}
      onOk={handleOk}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="newSubModal">
        <img alt="anouncement" className="announmentImage" src='./static/images/anouncement.png' />
        <div className="announmentText">

          <div className="headingDiv">
            <h3>Welcome to the all-new <img alt="anouncement" src='./static/images/anouncementLogo.png' /> </h3>
            <p>Where music meets perfection!</p>
          </div>
          <p className="text">
            We've fine-tuned every note, squashed every bug, and added exciting features just for you.
          </p>
          <p className="diveText">Dive into a seamless and enhanced music experience like never before. Let the rhythm of our fresh launch elevate your playlist journey!</p>
          <span className="whatsNew" onClick={() => {
            setSeeMore(!seeMore)
          }}>See what's new! <svg style={seeMore ?{transform:'rotate(-90deg)', transition:"0.2s"}:{transition:"0.2s"}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2835_17387)">
                <path d="M6 9L12 15L18 9" stroke="#55A2F7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_2835_17387">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>

          </span>

          {seeMore ?
            <>
              <h4 className="youCanSee">Now you can:</h4>
              <ul className="featureList">
                <li><p className="listItem">Add songs to multiple playlists at a time </p></li>
                <li><p className="listItem">Add songs to pre-release using their URI link </p></li>
                <li><p className="listItem">Add notes/descriptions to pre-release tracks </p></li>
                <li><p className="listItem">Lock the songs on their desired position with just one click</p></li>
                <li><p className="listItem">Refresh your playlists to modify each song's start date and time to the current date and time </p></li>
                <li><p className="listItem">Sync your playlists to bring songs back to their original position in case of misplaced due to any reason </p></li>
                <li><p className="listItem">Use filters for a better experience </p></li>
                <li><p className="listItem">Choose between multiple monthly or annually plans and your desired currency as the method payment</p></li>
              </ul>
              <p className="icluded">
                There is a lot more included to make your experience even better. Feel free to visit and check it out.
              </p>
            </>
            : null}


        </div>

      </div>
    </NewSubscriptionStyle>
  );
}

export default NewSubscription;
